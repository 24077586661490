import { FlightClass, PassengerType } from "@/models/flight";

export default {
  flightClass: {
    [FlightClass.Economy]: 'Economy',
    [FlightClass.Business]: 'Business',
  },

  searchPanel: {
    yourMessageIsOutOf: 'Your data is out of date Click the Refresh button',
    cancel: 'Cancel',
    Update: 'Update',
    errors: {
      selectFromLocation: 'Select your departure point',
      selectToLocation: 'Select your arrival point',
      selectFromDate: 'Select your departure date',
      selectToDate: 'Select your return date',
    },
    there: "There",
    backAndForth: "Back and forth"
  },

  directions: {
    title: "Directions",
    popular: 'Popular directions',
    isPopular: {
      yes: 'Популярность данного направления остается неизменно высокой.',
      no: 'Данное направление имеет умеренную популярность.',
    },
    isTheSameCountry: {
      yes: 'соединяет два города в пределах одного государства.',
      no: 'соединяет два города в разных государствах.',
    },
    locations: {
      tas: {
        name: "Tashkent",
        genitiveCase: "Tashkent",
      },
      mow: {
        name: "Moscow",
        genitiveCase: "Moscow",
      },
      ist: {
        name: "Istanbul",
        genitiveCase: "Istanbul",
      },
      cit: {
        name: "Shymkent",
        genitiveCase: "Shymkent",
      },
      kzn: {
        name: "Kazan",
        genitiveCase: "Kazan",
      },
      ugc: {
        name: "Urgench",
        genitiveCase: "Urgench",
      },
      bhk: {
        name: "Bukhara",
        genitiveCase: "Bukhara",
      },
      skd: {
        name: "Samarkand",
        genitiveCase: "Samarkand",
      },
      nma: {
        name: "Namangan",
        genitiveCase: "Namangan",
      },
      feg: {
        name: "Fergana",
        genitiveCase: "Fergana",
      },
      oss: {
        name: "Osh",
        genitiveCase: "Osh",
      },
      tmj: {
        name: "Termez",
        genitiveCase: "Termez",
      },
      led: {
        name: "St. Petersburg",
        genitiveCase: "St. Petersburg",
      },
      ovb: {
        name: "Novosibirsk",
        genitiveCase: "Novosibirsk",
      },
      ikt: {
        name: "Irkutsk",
        genitiveCase: "Irkutsk",
      },
      svx: {
        name: "Yekaterinburg",
        genitiveCase: "Yekaterinburg",
      },
      aer: {
        name: "Sochi",
        genitiveCase: "Sochi",
      },
      ala: {
        name: "Almaty",
        genitiveCase: "Almaty",
      },
      ufa: {
        name: "Ufa",
        genitiveCase: "Ufa",
      },
      kuf: {
        name: "Samara",
        genitiveCase: "Samara",
      },
      dme: {
        name: "Domodedovo",
        genitiveCase: "Domodedovo",
      },
      vko: {
        name: "Vnukovo",
        genitiveCase: "Vnukovo",
      },
      kej: {
        name: "Kemerovo",
        genitiveCase: "Kemerovo",
      },
      kja: {
        name: "Krasnoyarsk",
        genitiveCase: "Krasnoyarsk",
      },
      oms: {
        name: "Omsk",
        genitiveCase: "Omsk",
      },
      pee: {
        name: "Permian",
        genitiveCase: "Permian",
      },
      mcx: {
        name: "Makhachkala",
        genitiveCase: "Makhachkala",
      },
      ksq: {
        name: "Karshi",
        genitiveCase: "Karshi",
      },
      krr: {
        name: "Krasnodar",
        genitiveCase: "Krasnodar",
      },
    }
  },
  topRoutes: {
    title: 'Top routes from {fromGen}',
    titleWithoutLocation: 'Top routes',
    leadText: 'Most frequently flown cities',
  },

  countries: {
    russia: 'Russia',
    uzbekistan: 'Uzbekistan',
    kazakhstan: 'Kazakhstan',
    turkey: 'Turkey',
  },

  pages: {
    type: 'Tours',
    home: {
      title: 'Flight Booking, Cheap Air tickets. Prices for direct flights from Tashkent.',
      shortTitle: 'Buy air tickets in Tashkent.',
      titleH1: 'Search airline tickets and book online. Compare fares from different airlines and find the best prices.',
      titleH2: 'Travel around Uzbekistan at an affordable price!',
      description: 'Search cheap airline tickets and book online. Find and compare fares from different airlines. Direct flights from Tashkent and other cities of Uzbekistan and Russia.',
      leadText: `Buying air tickets on our website is a convenient way to plan your trip. 
        To purchase tickets, it makes sense to use our online service. 
        This way you can save both time and money.`,
      content: `<h2 style="margin: 1rem 0;">How much does the ticket cost?</h2>
            <p style="margin: .65rem 0;">
              Airfare is not fixed. The fare is formed based on seasonality, 
              demand for this direction on a specific date, etc. 
              Tariffs are calculated taking into account the following parameters:
            </p>
            <ul style="padding-left: 2rem;margin: 1rem;list-style: disc;">
              <li>Day of the week.</li>
              <li>Departure hour.</li>
              <li>Availability of connections.</li>
            </ul>
            <p style="margin: .65rem 0;">
              Prices for flights on weekends and holidays are traditionally higher. 
              Flights on these dates are in high demand.
            </p>
            <p style="margin: .65rem 0;">
              In the morning, the price of air tickets is higher.
               This is due to the increase in demand for flights from those traveling for work.
               Transportation in one direction turns out to be less profitable 
               than the simultaneous purchase of transportation there and back. 
               At the same time, night flights are usually cheaper.
            </p>
            <p style="margin: .65rem 0;">
              Much depends on the availability of a transplant. 
              Tickets with a long connection from 6-8 hours are cheaper.
               For a trip without a transfer, you will have to pay a large amount.
               Direct flights are more convenient in terms of time savings. 
               This is the fastest way to get to your destination. 
               In just 4 hours you can cover a distance of almost 3 thousand kilometers.
            </p>
            <p style="margin: .65rem 0;">
              The price of air tickets depends on the occupancy of the aircraft, ongoing promotions, discounts.
               The fewer seats left by the date of departure, the more expensive the trip will cost.
            </p>`,
    },
    directions: {
      title: 'Search air tickets from {from} to {to}. Booking direct flights from {from}.',
      titleH1: 'Search air tickets from {from} to {to}. Booking direct flights from {from}.',
      description: 'Search cheap airline tickets and book one-way or return flights from {from} to {to}. Find cheap flights on Avione and book the best deals',
      content: `<h2 style="margin-bottom: 1rem;">
                  Booking and buying air tickets from {fromGen} to {to}
                </h2>
                <p style="margin: .65rem 0;">
                  In 2022, the usual rules for setting tariffs could undergo some changes.
                   How much a flight costs depends on the exchange rate and other factors.
                  { popularText }
                  Therefore, it makes sense to take care of buying tickets for an 
                  aircraft following the specified route in advance.
                </p>
                <h3 style="margin: 1rem 0;">
                  Popularity of flights {fromGen} — {to}
                </h3>
                <p style="margin: .65rem 0;">
                  The flight route from {fromGen} to {to} connects two cities.
                  Air communication in this direction is well-developed.
                  Aircraft of several carriers fly between the airports daily.
                  It makes sense to buy tickets in advance to save money.
                  The lowest price is usually when booking 2 months before departure.
                  Ticket prices increase closer to the departure date.
                  Taking into account a specific month, there may be a change in 
                  the price of a flight on this route - both an increase and a decrease.
                  Typically, the most profitable offers are sorted out first.
                  However, sometimes you can buy a ticket at a low price and on the eve of departure.
                </p>
                <p style="margin: .65rem 0;">
                  The page contains all available offers for flights.
                   All flights listed in the search results are scheduled.
                   Air tickets can be filtered by the specified parameters.
                   A convenient search form with instant booking confirmation 
                   selects travel documents with guaranteed availability.
                   You just need to indicate the required date of departure, the number of passengers, the class of flight.
                </p>`
    },
    charterFlights: {
      title: 'Charter Flights',
      notFound: 'Unfortunately, there are no charter flights available at the moment.',
    }
  },

  title: {
    titleLang: "Avione.uz - Flights at affordable prices"
  },

  Мужчина: "Man",
  Женщина: "Woman",

  search: {
    Search: "Search...",
    fromLocation: "From",
    toLocation: "To",
    button: "Find",
    passengers: "passengers",
    Passengers: "Passengers",
    Back: "Back",
    There: "There",
    ChoosePlaceOfArrival: "Choose a place of arrival",
    SelectYourDeparturePoint: "Select your departure point",
    allAirports: "all airports"
  },
  search_result: {
    options: "options",
    Filters: "Filters",
    TheCheapest: "The cheapest",
    Buy: "Buy",
    FlightDetails: "Flight details",
    CollapseDetails: "Collapse details",
    OnMyWay: "On my way:",
    Transplants: "Transplants:",
    PieceOfHandLuggage: "1 piece of hand luggage",
    TransferTo: "Transfer to",
    OnTheWay: 'On the way',
    ChooseTrain: 'choose train',
    NotLeftSeat: 'seat not left'
  },
  booking_result_popup: {
    BookingResult: "Booking result",
    YourBooking: "Your booking №",
    createdSuccessfully: " created successfully.",
    ValidityOfYourBooking: "Validity of your booking until - {date}",
    Later: "Later",
    Pay: "Pay",
  },
  contact_info: {
    RecipientContacts: "Recipient contacts",
    Phone: "Phone",
    Email: "Email",
    WeWillSendTicketsInformAbout: "We will send tickets, inform about changes in the trip. We will contact you if you need more information."
  },
  extra_info: {
    Data: "Data",
    HandLuggage: "Hand luggage: 1 bag",
    Luggage: "Luggage:",
    Adult: "1 adult"
  },
  home: {
    flights: "Flights",
    Hotels: "Hotels",
    Welcome: "Welcome",
    tell: "We'll tell you, we'll show you where you can relax",
    show: "We will tell you, tell you and show you where you can relax",
    bonuses: "Using the mobile application, you get almost 2 times more bonuses than on the website.",
    main: "main",
    searching: "searching results",
    Checkout: "Checkout",
    variant: "variant",
  },
  About: {
    AboutUs: "About Us",
    newMobileApplication: "Avione is a new mobile application in Uzbekistan. Here you can find air tickets, compare prices on your own and buy in just a few seconds. Search, compare and buy tickets with online support 24/7",
    BuyAirTickets: "Buy airline tickets in just a few minutes to anywhere in the world, plane tickets from Aeroflot, Pobeda, S7 (c7, Siberia), Yamal, Ural Airlines, Utair (UTair), Air Baltic, low-cost airlines Air Berlin, Lufthansa, Air France, Emirates, British Airways and many more in one app.",
    searchForAirTickets: "The search for air tickets is carried out at any time of the day online. No more need to go to the office ticket office. The ticket will come directly to your Avione app and email, you just need toprint out and take with you to the airport.",
    ItISBetterTSearch: "It is better to search and book air tickets 4-6 months before the desired departure date. To do this, use the Avione app, featuring offers from over 700 airlines. Here you can easily monitor the dynamics of cost, time and day of departure and, as a result, order a plane ticket via the Internet.",
    yearsOld: "12 years old",
    MarketExperience: "Market experience",
    Узбекистана: "Uzbekistan",
    SatisfiedCustomers: "Satisfied customers",
    AirlinesFor: "Airlines for",
    ticketSearch: "ticket search",
    CountriesSearch: "Countries to search",
    hotels: "hotels",
    HowBuyTicketThroughAvione: "How to buy a ticket through Avione:",
    OpenAvioneApp: "Open the Avione App",
    SpecifyTheTimeCityOf: "Specify the time, city of departure and arrival",
    YouCanUseFiltersChooseTicket: "You can use the filters and choose a ticket that suits a large number of different",
    ChooseThePlaneTickets: "Choose the plane tickets that suit you",
    CreateAccountTheAvioneApp: "Create an account on the Avione App",
    EnterPassengerDetails: "Enter passenger details and pay for air tickets online",
    TheFlightTicketWillCome: "The flight ticket will come directly to your Avione app and to your email.",
    AirfarePricesIncludingFees: "Airfare prices including all fees",
    AllFlightPricesFinal: "All flight prices are final. No post-purchase fees",
    ToReturnTheTicket: "To return the ticket, you can contact our support team, they will help you quickly return the payment",
    Support: "Support",
    OurSuportCovers: "Our support covers a full range of services aimed at helping users 24/7, who canget a useful response to a query. Write or call us at any time, we will be happy to help you!",
    OurContacts: "Our contacts",
    Thanks: "Thanks,",
    WeHappyHelpYou: "We are happy to help you. Avion team.",
    AdditionalServices: "Additional services",
    WithUsTheTripMoreComfortable: "With us, the trip will be more comfortable and safer, because we can arrange flight insurance, pre-select a seat in the cabin",
    FlightInsurance: "Flight insurance",
    FlightDelayAndLostLuggage: "Cancellation, flight delay and lost luggage",
    Notifications: "Notifications",
    CancellationaSiaSMSAndEmail: "Flight changes or cancellation via SMS and e-mail",
    TicketReturn: "Ticket return",
    ReasonForReturnCanBeAny: "The reason for the return can be any",
    OnlineCheckInForFlight: "Online check-in for a flight",
    CheckYouInForFlight: "Check you in for a flight",
    BuyMoreLuggage: "Buy more luggage",
    ForOneOrAllPassengers: "For one or all passengers",
    AllInclusive: "All inclusive",
    IncludesFlightInsuranceService: "Includes flight insurance, notices, waives OneTwoTrip service fee."
  },
  footer: {
    company: "Company",
    oCompany: "About company",
    Flights: "Flights",
    Information: "Information",
    Offer: "Offer",
    Confidentiality: "Confidentiality",
    social: "We are in social networks",
    Download: "Download information",
    sell: "Online sale of air tickets in Tashkent",
    legal: "Tashkent city, Chilonzor, Katartal-8. Private enterprise \"Avione\", registered as a legal entity persons under the legislation of the Republic of Uzbekistan 03/05/2020, Registration Certificate No. 824437"

  },
  filter_panel: {
    Top: "Топ фильтр",
    luggage: "with luggage",
    noChange: "No airport change",
    Direct: "Direct",
    CheapFirst: "Cheap first",
    DearOnesFirst: "Dear ones first",
    ByDuration: "By duration",
    EarlyDeparture: "Early departure",
    LateCheckOut: "Late check out",
    Airlines: "Airlines",
    FilterCleaning: "Filter cleaning",
    Filter: "Filter"
  },
  login: {
    account: "Login to your personal account",
    phoneNumber: "Telephone number",
    Agree: "I have read and agree to the terms",
    publicOffer: "Public offer",
    privacyPolicy: "Privacy Policy",
    comeIn: "Login",
    EnterCode: "Enter the code we sent to your number",
    Code: "Send code again",
    EnterActivationCode: "Enter activation code",
    Proceed: "Proceed",
    Register: "Register",
    Profile: "Profile",
    Exit: "Exit"
  },

  passenger: {
    //profile.vue file inside
    Surname: "Surname",
    Name: "Name",
    MiddleName: "Middle name",
    DateOfBirth: "Date of Birth",
    Floor: "Floor",
    request: "We are looking for the best options for your request",
    PassengerData: "Passenger data",
    Ticket: "Ticket",
    Adult: "adult",
    Children: "child",
    ChildrenDiscount: "There is a discount for children from 5 to 10 years old",
    Child: "for a child under 5 years old (seatless)",
    AddChild: "Add a child under 5",
    RemoveChild: "Remove seat for child",
    EnterYourPersonalDetail: "Enter your personal details as indicated on the document you will be flying on. We will automatically translate the text into Latin - do not worry, everything is fine",
    PassengerType: "Passenger type",
    EnterPassengersLastName: "Enter passenger's last name",
    Email: "Email",
    DocumentType: "Document type",
    DocumentNumber: "Document Number",
    Region: "Region",
    PassportIssueDate: "Passport issue date",
    PassportExpirationDate: "Passport expiration date",
    Citizenship: "Citizenship",
    FullName: "Full name",
    Validity: "Validity",
    NewDocument: "New document",
    Phone: "Phone",
    man: "Man.",
    woman: "Woman.",
    Another: "Another",
    Cancel: "Cancel",
    Save: "Save",
    total: "Total",
    singular: 'passenger',
    plural: 'passengers',
    selectPassengerName: "Select the passenger name",
    type: {
      [PassengerType.Adult]: 'Adults',
      [PassengerType.Kid]: 'Kids',
      [PassengerType.Baby]: 'Babies',
    },
    age: {
      [PassengerType.Adult]: '12+ years old',
      [PassengerType.Kid]: '2-11',
      [PassengerType.Baby]: '0-1',
    }
  },
  AvioneMessage: {
    PAY_LATER: "Later",
    CONTINUE: "Continue",
    PAYMENT_BOOKING_VOID_FAIL: "Unfortunately, we were unable to cancel your ticket. Please try again later.",
    TICKET_VOIDING: "Cancellation of booked ticket...",
    PAYMENT_BOOKING_VOID_SUCCESS: "Your ticket has been successfully cancelled.",
    TICKET_PRICE_CHANGED: "The ticket price has changed, do you want to continue and pay at the new price?",
    TICKET_PRICE_CHANGED_TITLE: "Price of ticket has been changed",
    PHONE_NUMBER_REQUIRED: 'Phone number is required',
    SELECT_DEPARTURE_DATE: 'Please, select a flight date',
    SELECT_FLIGHT_LOCATION: "Please, select a flight location",
    PAYMENT_STATUS_SUCCESS: "Tickets issued successfully!",
    PAYMENT_STATUS_CHECKING_DISCHARGE: "Please wait, ticket is being issued...",
    discharged: "Discharged",
    eneterMiddleNamePassenger: "Enter middle name of passenger",
    bookingTicketNotFound: "Ticket for booking not found",
    sureToCancelBooking: "Are you sure to cancel your booking?",
    unable: "We were unable to determine the format of your phone number",
    selectRate: "Please select a rate",
    loginAgain: "Please login again and try again",
    EnterPassengerName: "Enter Passenger Name",
    EnterPassengersLastName: "Enter passenger's last name",
    EnterPassengersDateOfBirth: "Enter passengers date of birth",
    ChooseGender: "Choose gender",
    SelectNationality: "Select nationality",
    EnterPassengersPhoneNumber: "Enter the passenger's phone number",
    SelectDocumentType: "Select document type",
    EnterDocumentNumber: "Enter document number",
    WrongPhoneNumber: "Wrong phone number",
    WrongEmail: "Please enter email",
    WrongPassportIssue: "Please enter passport issue date",
    WrongPassportExpire: "Please enter passport expire date",
    PleaseSelectPaymentMethod: "Please select a payment method",
    WeWillRedirectYouPaymoPageForPayment: "We will redirect you to the Payme page for payment",
    WeWillRedirectYouAvipayPageForPayment: "We will redirect you to the Avipay page for payment",
    CheckingBillPayments: "Checking bill payments...",
    verifyYourPayments: "We were unable to verify your payments. You can pay before the booked ticket expires",
    PleaseWait: "Please wait...",
    OrderNotFound: "Order not found",
    WrongCode: "Wrong code",
    PleaseEnterSMSCode: "Please enter the SMS code we sent you",
    AllFieldsRequired: "All fields are required!",
    WeWereUnable: "We were unable to delete your order, please try again later.",
    PleaseLogIn: "Please log in to proceed with your booking.",
    AreYouSureYouCancelBourBooking: "Are you sure you want to cancel your booking?",
    //  YourTicketHasBeenSuccessfullyCancelled:"Your ticket has been successfully cancelled"
    DownloadTicket: "Download ticket",
    NotEnoughInBalance: 'Not Enough in Balance'
  },
  PersonalArea: {
    PersonalArea: "Personal Area",
    Orders: "Orders",
    Balans: "Balans",
    MyOrders: "My orders",
    MyVoid: 'Canceled',
    MyBookings: "My bookings",
    GoOut: "Go out",
    Notebook: "Notebook"
  },
  CheckAviabilty: {
    // Add empty_result.vue and check_aviabilty
    AvailabilityCheck: "Availability check",
    TariffAvailabilityCheck: "Tariff Availability Check",
    PleaseWaitBookingInProgress: "Please wait, booking in progress..."
  },
  flight_detail: {
    TheCheapest: "The cheapest",
    FlightDetails: "Flight details",
    CollapseDetails: "Collapse details",
    WaitingForDischarge: "Waiting for discharge",
    Canceled: "Canceled",
    Discharged: "Discharged",
    AreYouSureYouantDelete: "Are you sure you want to delete?",
    TimeLeftPay: "Time left to pay",
    PNRNumber: "PNR number",
    OrderNumber: "Order number",
    AmountPayable: "Amount payable",
    TermsOfReturnExchange: "Terms of return and exchange",
    Cancellation: "Cancellation",
    PayForTOrder: "Pay for the order",
    Order: 'Order'
  },
  payment_type: {
    SelectPaymentMethod: "Select a Payment Method",
    TicketsIssuedSuccessfully: "Tickets issued successfully!",
    ThankYouForUsingAvione: "Thank you for using Avione.",
    ToTheMain: "To the main",
    ThroughPaymentSystem: 'Through the payment system',
    RemoveFromBalance: 'Remove from balance',
    UseMixedPayment: 'Insufficient funds, please use “mixed payment”',
    MixedPayment: "Mixed payment",
    HowMuchFromBalance: 'How much do you want to pay from the balance?',
    Left: 'Rest',
    WillBePaidThroughPaymentSystem: "sum will be paid through the payment system"
  },
  price_change_popup: {
    TicketPriceHasChanged: "Ticket price has changed",
    OldPrice: "Old price",
    NewPrice: "- New price",
    TheTicketPriceHasChanged: "The ticket price has changed, do you want to continue and pay at the new price?",
    Later: "Later",
    Continue: "Continue"
  },
  balance_replanish_popup: {
    ReplanishBalance: "Replenishment of the balance",
    EnterReplenishmentAmount: "Enter replenishment amount",
    Replanish: "Top up"
  },
  steps: {
    //tarif.vue inside
    Booking: "1. Booking",
    Payment: "2. Payment",
    GettingTicket: "3. Getting a ticket",
    TicketBooking: "Ticket booking",
    Payments: "Payment",
    PaymentStatus: "Payment status",
    AirlineFares: "Airline fares",
    TashkentDubai: "Tashkent - Dubai"
  },
  Basic: {
    Cancel: "Cancel",
    ToBook: "To book",
    GoToThePayment: "Go to the payment",
    DownloadTicket: 'Donwload ticket'
  },
  empty_result: {
    Filtr: "Filtr",
    CheapFirst: "Cheap first",
    DearOnesFirst: "Dear ones first",
    MostWanted: "Most wanted",
    TopFilter: "Top filter",
    WithLuggage: "With luggage",
    NoAirportChange: "No airport change",
    Direct: "Direct",
    FlightDetails: "Flight details",
    Buy: "Buy"
  },
  avione_ads: {
    BigCashbackWallet: "Big Cashback Wallet 2% cashback on every purchase.",
    ToLearnMore: "To learn more",
    FindOutAboutUnusualTravel: "Find out about unusual travel destinations in&nbsp; our&nbsp; Telegram.",
    WeHaveCreated: "We have created a 24/7&nbsp;call center where you can send questions&nbsp;and&nbsp;requests by phone."
  },
  location_history: {
    SearchHistory: "Search History",
    ShowMore: "Show more",
    regionNijniy: "Nizhny Novgorod (GOJ) – Sheremetyevo (SVO)",
    EconomyClass: "26 Apr, 1 adult, Economy class",
    regionTashkent: "Tashkent (TAS) – Sheremetyevo (SVO)"
  },
  privacy: {
    AvionePrivacyPolicy: "Avione Privacy Policy",
    AboutUs: "About Us",
    privatelyOwnedCompany: "Частное предприятие Avione, очень серьезно относятся к Вашей конфиденциальности. Настоящая Политика\n" +
      "          конфиденциальности разработана с учетом Ваших потребностей, и важно, чтобы Вы имели полное представление о\n" +
      "          нашей практике сбора и использования личной информации, позволяя Вам при этом полностью контролировать личную\n" +
      "          информацию, которую Вы предоставляете Avione.",
    OnTermsOfThisAgreement: "На условиях настоящего Соглашения об обработке персональных данных (далее - Соглашение), Частное предприятие\n" +
      "          «Avione», зарегистрированному в качестве юридического лица по законодательству Республики Узбекистан\n" +
      "          05.03.2020 г., Свидетельство о регистрации №824437, почтовый адрес которого находится: Республика Узбекистан,\n" +
      "          г.Тошкент, Чилонзор, Катартал-8, Катортол (далее – «Агент») предоставляет пользователям приложения Avione,\n" +
      "          расположенного по адресу https://play.google.com/store/apps/details?id=com.avione.uz, право пользования\n" +
      "          приложения, при помощи которых Пользователи могут осуществить поиск авиабилетов и прочих услуг и предложений\n" +
      "          на рынке авиабилетов.",
    AboutThisPrivacyPolicy: "О настоящей Политике конфиденциальности",
    ThePrivacyPolicyAppliesAllAvioneDevices: "Политика конфиденциальности применяется ко всем устройствам, веб-сайтам и приложениям Avione, которые\n" +
      "          упоминаются или ссылаются на настоящую Политику конфиденциальности. Настоящая Политика конфиденциальности\n" +
      "          объясняет, как Avione собирает, использует, раскрывает, обрабатывает и защищает любую личную информацию,\n" +
      "          которую Вы нам предоставляете во время использования наших продуктов и услуг, доступ к которым Вы можете\n" +
      "          получить через веб-сайт https://www.avione.uz и наши приложения, которые мы предоставляем на наших мобильных\n" +
      "          устройствах. Мы будем использовать Вашу личную информацию строго в соответствии с настоящей Политикой\n" +
      "          конфиденциальности. Если этого требует контекст, личная информация также должна включать в себя\n" +
      "          конфиденциальные личные данные или информацию, которая может быть отнесена к категории в соответствии с\n" +
      "          применимым правом.",
    TheAvioneApplicationSellsAndBook: "Важное: приложения Avione осуществляет непосредственную продажу и бронирование авиабилетов пользователям.\n" +
      "          Администратор не выступает в качестве Поставщика услуг. Администратор со своей стороны обязуется делать все\n" +
      "          возможное для решения проблем пользователей, возникших при использовании приложения Avione, в том числе\n" +
      "          оказать пользователям помощь во взаимодействии с Поставщиком услуг.",
    thisDocumentWithAllAdditions: "Соглашение – настоящий документ со всеми дополнениями, изменениями и указанными в нем обязательными\n" +
      "          документами, а также договор, заключенный между Avione и Пользователем на его основании",
    onlineServiceAndroidAddress: "Приложения – онлайн-сервис по адресу Android: https://play.google.com/store/apps/details?id=com.avione.uz для\n" +
      "          поиска информации об авиабилетах, бронировании и приобретении авиабилетов, а также для облегчения\n" +
      "          (автоматизации) процесса их приобретения.",
    capableNaturalPerson: "Пользователь – дееспособное физическое лицо, достигшее 18 лет, имеющее законное право вступать в договорные\n" +
      "          отношения с Поставщиком услуг, в соответствии с 3 Главой Гражданского Кодекса Республики Узбекистан, способный\n" +
      "          в том числе совершать Заказ или бронировать услуги на приложения Avione, исключительно для личных нужд, не\n" +
      "          связанных с осуществлением предпринимательской деятельности.",
    PersonalDataAnyInformationThat: "Персональные данные – любая информация, которую Пользователь предоставляет о себе самостоятельно при\n" +
      "          регистрации на приложения Avione, а также в процессе дальнейшего использования приложения Avione. К такой\n" +
      "          информации, в частности, следует относить: Ваше Имя, Фамилия, номер мобильного телефона, адрес электронной\n" +
      "          почты, Дата Рождения, Номер документа, Срок действия паспорта, Дата выдачи паспорта, Гражданство, Пол, а также\n" +
      "          другую информацию, включая ряд технических данных: IP адрес, историю посещений приложения и другие.",
    PersonalAccountPersonalSection: "Личный кабинет — персональный раздел приложения, к которому Пользователь получает доступ после прохождения\n" +
      "          регистрации и/или авторизации на приложения. Личный кабинет предназначен для хранения персональных данных\n" +
      "          Пользователя (личные данные и документы), оформление Заказов, просмотр информации о совершенных Заказах,\n" +
      "          стадии их выполнения, текущее состояние выставленных счетов, и получения уведомлений.",
    TheUserWhenClickingOn: "Пользователь при нажатии на текст Соглашение об использовании персональных данных на форме авторизации, может\n" +
      "          ознакомится с информацией об условиях сбора и хранения Персональных данных.",
    WhenRegisteringTheUser: "При регистрации Пользователь самостоятельно, свободно, своей волей и в своих интересах дает Администратору\n" +
      "          бессрочное письменное согласие на любые способы обработки своих персональных данных.",
    TheUserMustIndependentlyFamiliarize: " Пользователь самостоятельно должен ознакомиться с условиями использования его персональных данных до начала их\n" +
      "          обработки. Приложения прекращает обработку Персональных данных и уничтожает их сразу после удаления учетной\n" +
      "          записи Пользователя. В этом случае Администратор сохраняет только обезличенные персональные данные\n" +
      "          Пользователя в своих системах баз данных.",
    TheAdministratorIsNotResponsible: "Администратор не отвечает за полноту и актуальность сведений о Пользователях. Пользователь вправе в любой\n" +
      "          момент самостоятельно отредактировать в своем Аккаунте предоставленные им при регистрации или авторизации\n" +
      "          Персональные данные.",
    HowCanWeHelpYou: "How can we help you",
    UltimatelyWeStriveTo: "В конечном итоге, мы стремимся предоставить наилучшие условия для всех наших пользователей. Если у Вас\n" +
      "          возникнут вопросы о наших методах обработки данных, описанных в настоящей Политике конфиденциальности, Вы\n" +
      "          можете перейти по адресу https://www.avione.uz/support для решения конкретных проблем. Мы будем рады помочь\n" +
      "          Вам.",
    WhatKindOfInformation: "1. What kind of information we collect and how we use it",
    WhatKindOfInformationcollect: "1.1. What kind of information do we collect",
    InOrderProvideYouWithOur: "Для предоставления Вам наших услуг мы попросим Вас предоставить личную информацию, необходимую для\n" +
      "          предоставления Вам этих услуг. Мы будем осуществлять сбор только той информации, которая необходима для\n" +
      "          указанных, конкретных, явных и законных целей, и будем следить за тем, чтобы такая информация не\n" +
      "          обрабатывалась в дальнейшем способом, несовместимым с этими целями. У Вас есть право решать, предоставлять ли\n" +
      "          запрошенную нами информацию или нет, но в большинстве случаев, если Вы не предоставите свою личную информацию,\n" +
      "          мы не сможем предоставить Вам наши продукты или услуги или ответить на Ваши запросы.\n" +
      "          Приложения со своей стороны синхронизирует историю поиска, настройки и избранное в личном аккаунте\n" +
      "          Пользователя.",
    InformationYouProvideToUs: "1.1.1. Информация, которую Вы нам предоставляете\n" +
      "          Чтобы использовать наше приложение, вы должны пройти нашу регистрацию.\n" +
      "          Чтобы зарегистрироваться в нашей системе, Вам необходимо предоставить Ваше Имя, Фамилия, номер мобильного\n" +
      "          телефона, адрес электронной почты, Дата Рождения, Номер документа, Срок действия паспорта, Дата выдачи\n" +
      "          паспорта, Гражданство, Пол. Чтобы забронировать билет в нашей системе Вам необходимо предоставить информация о\n" +
      "          вашем пассажире Имя, Фамилия, номер мобильного телефона, адрес электронной почты, Дата Рождения, Номер\n" +
      "          документа, Срок действия паспорта, Дата выдачи паспорта, Гражданство, Пол.",
    InformationCollectedWhenYou: "1.1.2. Информация, собираемая во время использования Вами нашими службами\n" +
      "          Администратор может использовать трансграничную передачу персональных данных на территорию иностранного\n" +
      "          государства иностранному юридическому лицу в целях исполнения договора с Пользователем. При достижении всех\n" +
      "          поставленных целей Администратор может гарантировать Пользователю уничтожение его Персональных данных на\n" +
      "          территории иностранного государства.",
    NonIdentifyingInformation: "1.1.3. Неидентифицирующая информация\n" +
      "          Мы также можем осуществлять сбор других типов информации, которая не связана прямо или косвенно с человеком и\n" +
      "          которая может быть определена как личная информация в соответствии с применимым местным законодательством.\n" +
      "          Такая информация называется неидентифицирующей. Мы можем собирать, использовать, передавать и раскрывать\n" +
      "          неидентифицирующую информацию. Ниже приведены несколько примеров информации, которую мы собираем, а также\n" +
      "          способы ее использования в агрегированном неидентифицирующем формате:",
    WeMayIncludeStatistical: "Мы можем включать статистические данные, которые генерируются, когда Вы используете Avione (например,\n" +
      "              неидентифицирующую информацию об устройстве, события ежедневного использования, события доступа к\n" +
      "              страницам).",
    NetworkNonitoringData: "Данные мониторинга сети (например, время запроса, количество запросов и запросов об ошибках и т. д.).",
    ApplicationFailureEvents: "События сбоев работы приложений (например, автоматическое создание журналов после обнаружения сбоев в\n" +
      "              работе приложений и т. д.).",
    ThePurposeOfThisCollectionOf: "Целью такого сбора информации является улучшение качества предоставляемых Вам услуг. Тип и объем\n" +
      "              собираемой информации зависит от того, как Вы используете наши продукты и/или услуги.",
    WeSummarizeSuchInformationIn: "Мы суммируем такую информацию, чтобы предоставлять Вам наиболее полезную информацию и понять, какие части\n" +
      "              наших приложений, веб-сайтов Вам наиболее интересны. Например, нам может потребоваться только количество\n" +
      "              активных пользователей за день. Но нам не нужно знать, кто конкретно был активен в конкретный день.\n" +
      "              Поэтому для статистического анализа достаточно агрегированных данных. Мы постараемся изолировать Ваши\n" +
      "              личные данные от неидентифицирующей информации и обеспечим раздельное использование этих двух типов\n" +
      "              данных. Однако, если мы объединяем неидентифицирующую информацию с личной информацией, такая объединенная\n" +
      "              информация будет рассматриваться как личная информация до тех пор, пока она остается объединенной.",
    HowWeUseThePersonal: "2. Как мы используем собранную нами личную информацию",
    ThePurposeOfCollectingYour: "Целью сбора вашей личной информации является аутентификация пользователей нашего приложения и идентификация их\n" +
      "          забронированных, оплаченных билетов и истории их пассажиров.",
    ProvidingProcessingMaintaining: "Предоставление, обработку, обслуживание, улучшение и развитие наших продуктов и/или услуг для Вас.",
    PromotionOfTheAvioneApp: "Продвижение приложения Avione на рынке путем осуществления прямых контактов с Пользователями с помощью\n" +
      "              сетей связи",
    InternalPurposesSuchAs: "Внутренние цели, такие как анализ данных, исследование и разработка статистической информации, связанной с\n" +
      "              использованием наших продуктов и услуг для последующего улучшения их качества. Например, машинное обучение\n" +
      "              или модель обучения алгоритмов выполняется после деидентификации.",
    OptimizingOurProductsAnd: " Оптимизация наших продуктов и/или услуг(приложения Avione и веб-сайт https://www.avione.uz).",
    StoringAndMaintainingInformation: "Хранение и обслуживание связанной с Вами информации для наших деловых операций или для выполнения наших\n" +
      "              юридических обязательств.",
    HowWeShareTransfer: "3. Как мы делимся, передаем и публично раскрываем Вашу личную информацию",
    Sharing: "3.1. Совместное использование",
    WeDoNotSellYourPersonal: "Мы не продаем Вашу личную информацию сторонним лицам.",
    WeMayOccasionally: "Иногда мы можем передавать Вашу личную информацию сторонним лицам (как описано ниже) для предоставления или\n" +
      "          улучшения качества наших продуктов и услуг, включая предложение продуктов или услуг на основе Ваших\n" +
      "          требований. Дополнительная информация о совместном использовании данных изложена ниже.",
    SharingInformationAbout: "3.1.1. Передача сведений о том, что Вы активно выбираете или запрашиваете\n" +
      "          С Вашего согласия или по Вашему запросу мы поделимся Вашей личной информацией в рамках Вашего согласия/запроса\n" +
      "          с конкретными третьими лицами, обозначенными Вами.",
    SharingWithThirdParty: "3.1.2. Совместное использование со сторонними поставщиками услуг и деловыми партнерами\n" +
      "          Чтобы помочь нам предоставить Вам продукты и услуги, описанные в настоящей Политике конфиденциальности, мы\n" +
      "          можем при необходимости передавать Вашу личную информацию нашим сторонним поставщикам услуг и деловым\n" +
      "          партнерам.\n" +
      "          К ним относятся наши поставщики услуг доставки, центры обработки данных, хранилища данных, поставщики услуг\n" +
      "          для клиентов, поставщики рекламных и маркетинговых услуг и другие деловые партнеры.\n" +
      "          Мы стремимся к тому, чтобы передача личной информации, необходимой для предоставления Вам услуг,\n" +
      "          осуществлялась исключительно в легальных, законных, необходимых, конкретных и явных целях. Avione будет\n" +
      "          проводить комплексную проверку и заключать контракты, чтобы гарантировать, что сторонние поставщики услуг\n" +
      "          соблюдают применимые законы о конфиденциальности в Вашей юрисдикции.",
    ToProvidePerformanceMeasurement: "Для обеспечения измерения производительности, анализа и других бизнес-услуг мы также можем делиться\n" +
      "          информацией (неличной информацией) с третьими сторонами (такими как рекламодатели на наших веб-сайтах) в\n" +
      "          обобщенном виде. Мы используем имеющуюся у нас информацию для обеспечения помощи рекламодателям и другим\n" +
      "          деловым партнерам в оценке эффективности и охвата их рекламы и услуг, а также для понимания типов людей,\n" +
      "          которые используют их услуги, и того, как люди взаимодействуют с их веб-сайтами, приложениями и службами. Мы\n" +
      "          также можем делиться с ними общими тенденциями использования наших услуг, такими как количество клиентов в\n" +
      "          определенной группе людей",
    Broadcast: "3.2. Передача",
    AvioneWillNotShareYour: "Avione не будет передавать Вашу информацию какому-либо субъекту, за исключением следующих случаев:",
    IfWeHaveObtained: "Если мы получили Ваше явное согласие.",
    AtTheRequestOfCourt: "По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством\n" +
      "              процедуры",
    UnderTheCircumstancesDescribed: "При обстоятельствах, описанных в настоящей Политике конфиденциальности или в любом другом уведомлении.",
    ToProtectTheRightsAndLegitimate: "Для защиты прав и законных интересов Администратора в связи с нарушением заключенных с Пользователем\n" +
      "              договоров",
    PublicDisclosure: "3.3. Публичное раскрытие",
    AvioneMayPublicly: "Avione может публично раскрыть Вашу личную информацию при следующих обстоятельствах:",
    WhenWeNeedAnnounce: "• Когда нам нужно объявить победителя рекламной акции, конкурса или розыгрыша. В этом случае мы публикуем\n" +
      "              только ограниченную информацию.",
    IfWeHaveObtainedYour: "• Если мы получили Ваше явное согласие или если Вы раскрыли информацию через наши службы, например, на\n" +
      "              страницах в социальных сетях или на общедоступных форумах. Или",
    PublicDisclosureOfInformation: "• Публичное раскрытие информации на основании закона или уместных оснований: включая законы и\n" +
      "              постановления, юридические процедуры, судебные разбирательства или по запросу компетентных государственных\n" +
      "              ведомств.",
    Security: "4. Безопасность",
    TheAdministratorStoresPersonal: "Администратор хранит Персональные данные и обеспечивает их охрану от несанкционированного доступа и\n" +
      "          распространения в соответствии с внутренними правилами и регламентами. В отношении Персональных данных\n" +
      "          Пользователя сохраняется их конфиденциальность, за исключением случаев, когда технология Приложения либо\n" +
      "          настройки используемого Пользователем программного обеспечения предусматривают открытый обмен информацией с\n" +
      "          иными Пользователями приложения или с любыми пользователями сети Интернет.",
    PrivacyPolicyAndUse: "5. Политика конфиденциальности и использование персональной информации",
    ByUsingTheAvioneApps: "Используя приложения Avione, Вы подтверждаете, что Вы ознакомлены и согласны со всеми положениями и условиями,\n" +
      "          а также с политикой конфиденциальности, которая включает в себя использование персональной информации.\n" +
      "          Соглашение может быть изменено Администратором в одностороннем порядке без предварительного уведомления\n" +
      "          Пользователя. Новая редакция Соглашения вступает в силу с момента ее размещения по адресу\n" +
      "          https://test-frontend.avione.uz/privacy\n" +
      "          При внесении изменений в актуальной редакции указывается дата последнего обновления.",
    FinalProvisions: "6. Заключительные положения",
    TheAgreementIsGovernedBy: "Cоглашение регулируется и толкуется в соответствии с Республики Узбекистан и является действующим для всех\n" +
      "          Пользователей. Все вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с\n" +
      "          законодательством Республики Узбекистан."

  },

  RailwayTariff: {
    ChooseCarriageAndSeat: "Choose a carriage and seat",
    Price: "Price:",
    Carriage: "Carriage:",
    ChooseUpToFourSeat: "Choose up to 4 seats:",
    FreePlaces: "Free places",
    ChoosenCarriages: "Choosen carriage",
    AirConditioner: "air conditioner",
    SelectedSeats: "Selected seats",
    ServiceCost: "Service cost",
    TotalAmount: "Total amount"
  },

  Yes: "Yes",
  No: "No",

  Currency: 'som',
  "not required": 'not required'
}
